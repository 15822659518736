import {createAction} from 'redux-actions';
import {
	RELEASES_LIST_SET_RELEASES,
	RELEASES_LIST_SET_DISTRIBUTORS,
	RELEASES_LIST_TOGGLE_FILTER,
	RELEASES_LIST_SET_REGION_FILTER,
	RELEASES_LIST_SET_START_VISIBLE_DATE,
	RELEASES_LIST_ADD_RELEASES_TO_FAVORITES,
	RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES,
	RELEASES_LIST_SET_RELEASES_TO_FAVORITES,
	RELEASES_LIST_UPDATE_RELEASE,
	RELEASES_LIST_ADD_RELEASES,
	RELEASES_LIST_SET_IS_FETCHING,
	RELEASES_LIST_SET_SCROLL_TOP,
	RELEASES_LIST_SET_DISTRIBUTOR_FILTER,
	RELEASES_LIST_SET_FORMAT_FILTER,
	RELEASES_LIST_SET_IS_RELEASE_DELETED,
	RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE,
	RELEASES_LIST_SET_ROUTE,
} from 'app/releases_list/actions/actionsTypes';
import {googleTMAction} from 'app/common/googleTM';

export const setReleasesRoute = createAction(
	RELEASES_LIST_SET_ROUTE,
	route => route,
);

export const setReleases = createAction(
	RELEASES_LIST_SET_RELEASES,
	releases => ({releases}),
);

export const setDistributors = createAction(
	RELEASES_LIST_SET_DISTRIBUTORS,
	distributors => ({distributors}),
);

export const toggleFilter = createAction(
	RELEASES_LIST_TOGGLE_FILTER,
	filterType => ({filterType}),
);

export const setRegionFilter = createAction(
	RELEASES_LIST_SET_REGION_FILTER,
	regionFilter => ({regionFilter}),
	() => googleTMAction('release-list-region-filter'),
);

export const setDistributorFilter = createAction(
	RELEASES_LIST_SET_DISTRIBUTOR_FILTER,
	distributorID => ({distributorID}),
	() => googleTMAction('release-list-distributor-format'),
);

export const setFormatFilter = createAction(
	RELEASES_LIST_SET_FORMAT_FILTER,
	formatID => ({formatID}),
	() => googleTMAction('release-list-change-format'),
);

export const setStartVisibleDate = createAction(
	RELEASES_LIST_SET_START_VISIBLE_DATE,
	startVisibleDate => ({startVisibleDate}),
);

export const addReleaseToFavorites = createAction(
	RELEASES_LIST_ADD_RELEASES_TO_FAVORITES,
	(releaseID, cinemaIDs) => ({releaseID, cinemaIDs}),
);

export const deleteReleaseFromFavorites = createAction(
	RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES,
	(releaseID, cinemaIDs) => ({releaseID, cinemaIDs}),
);

export const setReleasesToFavorites = createAction(
	RELEASES_LIST_SET_RELEASES_TO_FAVORITES,
	(releaseID, cinemaIDs) => ({releaseID, cinemaIDs}),
);

export const updateRelease = createAction(
	RELEASES_LIST_UPDATE_RELEASE,
	newRelease => ({newRelease}),
);

export const addReleases = createAction(
	RELEASES_LIST_ADD_RELEASES,
	releases => ({releases}),
);

export const setIsFetching = createAction(
	RELEASES_LIST_SET_IS_FETCHING,
	isFetching => ({isFetching}),
);

export const setScrollTop = createAction(
	RELEASES_LIST_SET_SCROLL_TOP,
	releasesListScrollTop => ({releasesListScrollTop}),
);

export const setIsReleaseDeleted = createAction(
	RELEASES_LIST_SET_IS_RELEASE_DELETED,
	isReleaseDeleted => ({isReleaseDeleted}),
);

export const setTempPosterForNewRelease = createAction(
	RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE,
	(releaseID, cover) => ({releaseID, cover}),
);
