export const RELEASES_LIST_SET_ROUTE = 'RELEASES_LIST_SET_ROUTE';
export const RELEASES_LIST_SET_RELEASES = 'RELEASES_LIST_SET_RELEASES';
export const RELEASES_LIST_UPDATE_RELEASE = 'RELEASES_LIST_UPDATE_RELEASE';
export const RELEASES_LIST_ADD_RELEASES = 'RELEASES_LIST_ADD_RELEASES';
export const RELEASES_LIST_SET_DISTRIBUTORS = 'RELEASES_LIST_SET_DISTRIBUTORS';
export const RELEASES_LIST_SET_DISTRIBUTOR_FILTER = 'RELEASES_LIST_SET_DISTRIBUTOR_FILTER';
export const RELEASES_LIST_SET_FORMAT_FILTER = 'RELEASES_LIST_SET_FORMAT_FILTER';
export const RELEASES_LIST_TOGGLE_FILTER = 'RELEASES_LIST_TOGGLE_FILTER';
export const RELEASES_LIST_SET_REGION_FILTER = 'RELEASES_LIST_SET_REGION_FILTER';
export const RELEASES_LIST_SET_START_VISIBLE_DATE = 'RELEASES_LIST_SET_START_VISIBLE_DATE';
export const RELEASES_LIST_ADD_RELEASES_TO_FAVORITES = 'RELEASES_LIST_ADD_RELEASES_TO_FAVORITES';
export const RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES = 'RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES';
export const RELEASES_LIST_SET_RELEASES_TO_FAVORITES = 'RELEASES_LIST_SET_RELEASES_TO_FAVORITES';
export const RELEASES_LIST_SET_IS_FETCHING = 'RELEASES_LIST_SET_IS_FETCHING';
export const RELEASES_LIST_SET_SCROLL_TOP = 'RELEASES_LIST_SET_SCROLL_TOP';
export const RELEASES_LIST_SET_IS_RELEASE_DELETED = 'RELEASES_LIST_SET_IS_RELEASE_DELETED';
export const RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE = 'RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE';
